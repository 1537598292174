
import { getSystemFilesURL } from "@/helpers";
import Vue from "vue";

export default Vue.extend({
  name: "error404",
  data() {
    return {};
  },
  computed: {
    homeIcon(): string[] {
      return ["fal", "home"];
    },
    backIcon(): string[] {
      return ["fal", "arrow-left"];
    },
    get404ImageUrl(): string {
      return getSystemFilesURL("404");
    }
  }
});
